import React, { memo, lazy, Suspense } from "react";
import HeroSection from "./banner-comp/HeroSection";

const Elipse = lazy(() => import("./banner-comp/Elipse"));
const Into = lazy(() => import("./banner-comp/Into"));
const ServicesSection = lazy(() => import("./banner-comp/ServicesSection"));
const Student = lazy(() => import("./banner-comp/Student"));
const Says = lazy(() => import("./banner-comp/Says"));
const Courser = lazy(() => import("./banner-comp/Courser"));
const AutoPlay = lazy(() => import("./banner-comp/AutoPlay"));
const Package = lazy(() => import("./banner-comp/Package"));
const Team = lazy(() => import("./banner-comp/Team"));

function Banner() {
  return (
    <>
      <div data-aos="fade-down">
        <HeroSection />
        <Suspense fallback={<div>Loading...</div>}>
          <Elipse />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Into />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Courser />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <AutoPlay />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <ServicesSection />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Student />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Team />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Package />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Says />
        </Suspense>
      </div>
    </>
  );
}

export default memo(Banner);
