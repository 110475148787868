import React from 'react'
import job from "../images/openings.jpg"
function Opening() {
  return (
    <div className='jobopen'>
      <img src={job} alt={job}></img>
    </div>
  )
}

export default Opening