import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
const HeroSection = () => {
  return (
    <>
      <div>
        <section className="relative py-12 overflow-hidden bg-[#1c1929] sm:pb-16 lg:pb-20 xl:pb-24">
          <div className="px-4 mx-auto relativea sm:px-6 lg:px-8 max-w-[85rem]">
            <div className="grid items-center grid-cols-1 gap-y-12 lg:grid-cols-2 gap-x-16">
              <div>
                <h1 className="text-4xl bold text-white sm:text-5xl lg:text-6xl xl:text-6xl">
                  Welcome to the World of Technologies.
                </h1>
                <p className="mt-4 text-lg font-normal text-gray-400 sm:mt-8">
                  Unlock your potential with cutting-edge IT education at our
                  institute, where innovation meets opportunity.
                </p>
                <div className="mt-8 sm:mt-12">
                  <p className="text-lg font-normal text-white">
                    Trusted by 74k+ Learners in last 26 Years
                  </p>
                  <div className="flex items-center mt-3">
                    <div className="flex">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.8586 1.71248C8.2178 0.606908 9.7819 0.606908 10.1412 1.71248L11.4246 5.66264C11.5853 6.15706 12.046 6.49182 12.5659 6.49182H16.7193C17.8818 6.49182 18.3651 7.9794 17.4247 8.6626L14.0645 11.104C13.6439 11.4095 13.4679 11.9512 13.6286 12.4456L14.912 16.3958C15.2713 17.5013 14.0059 18.4207 13.0654 17.7374L9.7052 15.2961C9.2846 14.9905 8.7151 14.9905 8.2945 15.2961L4.93434 17.7374C3.99388 18.4207 2.72851 17.5013 3.08773 16.3958L4.37121 12.4456C4.53186 11.9512 4.35587 11.4095 3.93529 11.104L0.575078 8.6626C-0.365372 7.9794 0.117958 6.49182 1.28043 6.49182H5.43387C5.95374 6.49182 6.41448 6.15706 6.57513 5.66264L7.8586 1.71248Z"
                          fill="#F3E673"
                        />
                      </svg>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.8586 1.71248C8.2178 0.606908 9.7819 0.606908 10.1412 1.71248L11.4246 5.66264C11.5853 6.15706 12.046 6.49182 12.5659 6.49182H16.7193C17.8818 6.49182 18.3651 7.9794 17.4247 8.6626L14.0645 11.104C13.6439 11.4095 13.4679 11.9512 13.6286 12.4456L14.912 16.3958C15.2713 17.5013 14.0059 18.4207 13.0654 17.7374L9.7052 15.2961C9.2846 14.9905 8.7151 14.9905 8.2945 15.2961L4.93434 17.7374C3.99388 18.4207 2.72851 17.5013 3.08773 16.3958L4.37121 12.4456C4.53186 11.9512 4.35587 11.4095 3.93529 11.104L0.575078 8.6626C-0.365372 7.9794 0.117958 6.49182 1.28043 6.49182H5.43387C5.95374 6.49182 6.41448 6.15706 6.57513 5.66264L7.8586 1.71248Z"
                          fill="#F3E673"
                        />
                      </svg>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.8586 1.71248C8.2178 0.606908 9.7819 0.606908 10.1412 1.71248L11.4246 5.66264C11.5853 6.15706 12.046 6.49182 12.5659 6.49182H16.7193C17.8818 6.49182 18.3651 7.9794 17.4247 8.6626L14.0645 11.104C13.6439 11.4095 13.4679 11.9512 13.6286 12.4456L14.912 16.3958C15.2713 17.5013 14.0059 18.4207 13.0654 17.7374L9.7052 15.2961C9.2846 14.9905 8.7151 14.9905 8.2945 15.2961L4.93434 17.7374C3.99388 18.4207 2.72851 17.5013 3.08773 16.3958L4.37121 12.4456C4.53186 11.9512 4.35587 11.4095 3.93529 11.104L0.575078 8.6626C-0.365372 7.9794 0.117958 6.49182 1.28043 6.49182H5.43387C5.95374 6.49182 6.41448 6.15706 6.57513 5.66264L7.8586 1.71248Z"
                          fill="#F3E673"
                        />
                      </svg>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.8586 1.71248C8.2178 0.606908 9.7819 0.606908 10.1412 1.71248L11.4246 5.66264C11.5853 6.15706 12.046 6.49182 12.5659 6.49182H16.7193C17.8818 6.49182 18.3651 7.9794 17.4247 8.6626L14.0645 11.104C13.6439 11.4095 13.4679 11.9512 13.6286 12.4456L14.912 16.3958C15.2713 17.5013 14.0059 18.4207 13.0654 17.7374L9.7052 15.2961C9.2846 14.9905 8.7151 14.9905 8.2945 15.2961L4.93434 17.7374C3.99388 18.4207 2.72851 17.5013 3.08773 16.3958L4.37121 12.4456C4.53186 11.9512 4.35587 11.4095 3.93529 11.104L0.575078 8.6626C-0.365372 7.9794 0.117958 6.49182 1.28043 6.49182H5.43387C5.95374 6.49182 6.41448 6.15706 6.57513 5.66264L7.8586 1.71248Z"
                          fill="#F3E673"
                        />
                      </svg>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.8586 1.71248C8.2178 0.606908 9.7819 0.606908 10.1412 1.71248L11.4246 5.66264C11.5853 6.15706 12.046 6.49182 12.5659 6.49182H16.7193C17.8818 6.49182 18.3651 7.9794 17.4247 8.6626L14.0645 11.104C13.6439 11.4095 13.4679 11.9512 13.6286 12.4456L14.912 16.3958C15.2713 17.5013 14.0059 18.4207 13.0654 17.7374L9.7052 15.2961C9.2846 14.9905 8.7151 14.9905 8.2945 15.2961L4.93434 17.7374C3.99388 18.4207 2.72851 17.5013 3.08773 16.3958L4.37121 12.4456C4.53186 11.9512 4.35587 11.4095 3.93529 11.104L0.575078 8.6626C-0.365372 7.9794 0.117958 6.49182 1.28043 6.49182H5.43387C5.95374 6.49182 6.41448 6.15706 6.57513 5.66264L7.8586 1.71248Z"
                          fill="url(#paint0_linear_49_89)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_49_89"
                            x1="-2.21054e-09"
                            y1="1"
                            x2="18"
                            y2="1"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop offset="0.616962" stopColor="#F3E673" />
                            <stop offset="0.620507" stopColor="#F0E3E3" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <span className="ml-2 text-base font-normal text-white">
                      {" "}
                      4.7/5{" "}
                    </span>
                    <span className="ml-1 text-base font-normal text-gray-500">
                      {" "}
                      (341+ Google reviews ){" "}
                    </span>
                  </div>
                </div>
              </div>
              <div className="relative">
                <div className="absolute inset-0">
                  <LazyLoadImage
                    className="object-cover w-full h-full opacity-50"
                    src="https://newus.in/images/other/hero-back.png"
                    alt=""
                  />
                </div>
                <LazyLoadImage
                  effect="blur"
                  src="https://newus.in/images/other/hero-img.png"
                  className="img-fluid animated"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default HeroSection;
