import React from "react";

function Form(props) {
  return (
    <div className="popup-box">
      <div className="box">
        {props.content}
        <span className="close-icon" onClick={props.handleClose}>
          x
        </span>
      </div>
    </div>
  );
}

export default Form;
