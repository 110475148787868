import React, { memo } from "react";
import { Link } from "react-router-dom";
import coursecontent from "./Coursecontent";
import { LazyLoadImage } from "react-lazy-load-image-component";
function Footer() {
  const coursesToShow = coursecontent.filter(
    (course) => course.nav === "Navbar"
  );
  return (
    <section
      className="py-20 footer"
    >
      <div className="container px-4 mx-auto">
        <div className="max-w-7xl mx-auto">
          <div className="pb-10 mb-10 border-b border-gray-700 items-center">
          </div>
          <div className="flex flex-wrap -mx-4 mb-18">
            <div className="w-full lg:w-2/5 px-4 mb-12 lg:mb-0">
            <div className="w-full lg:w-1/2 px-4 mb-4 lg:mb-0">
                <Link className="inline-block" to="#">
                  <LazyLoadImage
                  effect="blur"
                    className="h"
                    src="https://newus.in/images/logo/white-logo.png"
                    alt=""
                    data-config-id="auto-img-1-3"
                  />
                </Link>
                <p className="bold text-white">newus work hard to assure 100% client satisfaction with high-quality work and timely delivery.</p>
              </div>
            </div>
            <div className="w-full sm:w-1/3 lg:w-1/5 px-4 mb-8 sm:mb-0">
              <h5
                className="text-lg font-semibold text-gray-50 mb-4"
                data-config-id="auto-txt-5-3"
              >
                Programs
              </h5>
              <ul>
                {coursesToShow.map((til, index) => {
                    return (
                      <li key={index} className="mb-4">
                        <Link
                          to={`/Course/${til.name}`}
                          className="inline-block text-gray-300 hover:text-gray-200 regular"
                        >
                          {til.title}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="w-full sm:w-1/3 lg:w-1/5 px-4 mb-8 sm:mb-0">
              <h5
                className="text-lg font-semibold text-gray-50 mb-3"
                data-config-id="auto-txt-10-3"
              >
                Useful Link
              </h5>
              <ul>
                <li className="mb-4">
                <Link
                  to="/"
                  className="inline-block text-gray-300 hover:text-gray-200"
                >
                  <span>Home</span>
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/CourseList"
                  className="inline-block text-gray-300 hover:text-gray-200"
                >
                  <span>Course</span>
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="/Contact"
                  className="inline-block text-gray-300 hover:text-gray-200"
                >
                  <span>Contact</span>
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to="#"
                  className="inline-block text-gray-300 hover:text-gray-200"
                >
                  <span>About</span>
                </Link>
              </li>
              </ul>
            </div>
            <div className="w-full sm:w-1/3 lg:w-1/5 px-4">
              <h5
                className="text-lg font-semibold text-gray-50 mb-4"
                data-config-id="auto-txt-15-3"
              >
                Help
              </h5>
              <ul>
                <li>
                  <Link
                    className="inline-block text-gray-300 hover:text-gray-200"
                    to="/Contact"
                    data-config-id="auto-txt-17-3"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="sm:flex items-center justify-between">
            <div className="sm:flex mb-6 sm:mb-0 items-center">
              <p
                className="inline-block mr-6 sm:mr-12 text-gray-300 hover:text-gray-200"
                to="#"
                data-config-id="auto-txt-18-3"
              >
                © newus.in
              </p>
            </div>
            <div className="flex items-center">
              <ul className="wrapper">
                <li className="icon facebook">
                  <Link
                    to="https://www.facebook.com/people/Newus-Dharamshala/61554449250059/?_rdr"
                    target="_"
                  >
                    <span className="tooltip">Facebook</span>
                    <svg
                      viewBox="0 0 320 512"
                      height="1.2em"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                    </svg>
                  </Link>
                </li>
                <li className="icon twitter">
                  <Link
                    to="https://in.linkedin.com/in/newus-dharamshala-65600b233"
                    target="_"
                  >
                    <span className="tooltip">Linkedin</span>
                    <svg
                      fill="currentColor"
                      height="1.2em"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 310 310"
                      space="preserve"
                    >
                      <g id="XMLID_801_">
                        <path
                          id="XMLID_802_"
                          d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73 C77.16,101.969,74.922,99.73,72.16,99.73z"
                        />
                        <path
                          id="XMLID_803_"
                          d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4 c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
                        />
                        <path
                          id="XMLID_804_"
                          d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599 c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319 c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995 C310,145.43,300.549,94.761,230.454,94.761z"
                        />
                      </g>
                    </svg>
                  </Link>
                </li>
                <li className="icon instagram">
                  <Link
                    to="https://www.instagram.com/newusdharamshala/"
                    target="_"
                  >
                    <span className="tooltip">Instagram</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.2em"
                      fill="currentColor"
                      className="bi bi-instagram"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                    </svg>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default memo(Footer);
